import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { HyperButton } from "./Hyperbutton";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDappDropdownOpen, setIsDappDropdownOpen] = useState(false);

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    //setIsDappDropdownOpen(false); // Ensure Dapp dropdown is closed when toggling mobile menu
  };

  const toggleDappDropdown = () => {
    setIsDappDropdownOpen(!isDappDropdownOpen); // Toggle the dropdown state
  };


  const handleClick = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  //const handleClick = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setIsDappDropdownOpen(false);
    } else {
      setIsDappDropdownOpen(true);
    }
  };

  const onMouseLeave = () => {
    setIsDappDropdownOpen(false);
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-left">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img src="images/logo.jpeg" alt="logo" className="navbar-logo" />
          </Link>
        </div>



        <div className="menu-icon" onClick={handleClick}>
          <i className={isMobileMenuOpen ? "fas fa-times" : "fas fa-bars"} />
        </div>

        <ul className={isMobileMenuOpen ? "nav-menu active" : "nav-menu"}>
          {/* Telegram Link */}
          <li className="nav-item">
            <a
              href="https://t.me/hypersol"
              target="_blank"
              rel="noopener noreferrer"
              className="logo-container"
            >
              <img src="images/tg.svg" alt="Telegram" className="logo" />
            </a>
          </li>

          {/* X (formerly Twitter) Link */}
          <li className="nav-item">
            <a
              href="https://x.com/hypersolX"
              target="_blank"
              rel="noopener noreferrer"
              className="logo-container"
              onClick={closeMobileMenu}
            >
              <img src="images/x.svg" alt="Twitter" className="logo" />
            </a>
          </li>

          {/* YouTube Link */}
          <li className="nav-item">
            <a
              href="https://www.youtube.com/@hyperSolX"
              target="_blank"
              rel="noopener noreferrer"
              className="logo-container"
              onClick={closeMobileMenu}
            >
              <img src="images/youtube.svg" alt="YouTube" className="logo" />
            </a>
          </li>

          {/* Medium Link */}
          <li className="nav-item">
            <a
              href="https://hypersol.medium.com"
              target="_blank"
              rel="noopener noreferrer"
              className="logo-container"
              onClick={closeMobileMenu}
            >
              <img src="images/medium.svg" alt="Medium" className="logo" />
            </a>
          </li>

          <li className="nav-item">
            <a href="https://app.hypersol.xyz" className="logo-container" onClick={closeMobileMenu}>
              <HyperButton classNames="mediumBtn">HYPERPAD</HyperButton>
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;


