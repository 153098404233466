import React, { useState } from "react";
import { Copy } from "lucide-react"; 
import "./Hero.css";
import { HyperButton } from "./Hyperbutton";

const HomePage = () => {
  const contractAddress = "8DHjvtSvgoiciQWemUo4ntSE9rv53eeTPWvut4dYmoon"; // Replace with actual address
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(contractAddress);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset after 2 sec
  };

  return (
    <section className="homepage">
      <div className="hero-content">
        <h1 className="hero-heading">
          The Ultimate Tool Suite on <span className="highlight">Solana</span>
        </h1>
        <p className="hero-paragraph">
          Automate every aspect of project management—from token creation to market making—effortlessly.
        </p>

        {/* Contract Address Section */}
        <div className="contract-container">
          <span className="contract-text">Contract Address:</span>
          <span className="contract-address">{contractAddress}</span>
          <button className="copy-button" onClick={handleCopy}>
            <Copy size={18} />
          </button>
          {copied && <span className="copied-message">Copied!</span>}
        </div>

        {/* Buttons */}
        <a href="https://docs.hypersol.xyz/" target="_blank" rel="noopener noreferrer">
          <HyperButton classNames="largeBtn">Documentation</HyperButton>
        </a>
      </div>

      <div className="right-image">
        <img src="images/hero.jpg" alt="Hero Illustration" className="r-img" />
      </div>
    </section>
  );
};

export default HomePage;
